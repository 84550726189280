<template>
  <div id="salary" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-1"
        >VACACIONES DISFRUTADAS DEL MES EN CURSO</v-row
      >
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card class="mx-12 tablaReportes">
            <table style="width: 100%">
              <thead>
                <tr>
                  <th>No.Empleado</th>
                  <th>Empleado</th>
                  <th>Puesto</th>
                  <th>Área</th>
                  <th>Fecha de inicio</th>
                  <th>Fecha fin</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(reporte, r) in reporteGenerado"
                  :key="r"
                  class="text-center"
                >
                  <td class="text-center">
                    {{ reporte.empleadoId }}
                  </td>
                  <td class="text-center">
                    {{ reporte.nombreEmpleado }}
                  </td>
                  <td class="text-center">
                    {{ reporte.puesto }}
                  </td>
                  <td class="text-center">
                    {{ reporte.area }}
                  </td>
                  <td class="text-center">
                    {{ reporte.fechaInicio }}
                  </td>
                  <td class="text-center">
                    {{ reporte.fechaFin }}
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="sinDatos" justify="center">
        <v-col sm="5" md="5" lg="5" xl="5" align-self="center">
          <v-card class="ml-2 tablaReportes letraTabla" width="500">
            <table width="100%">
              <thead>
                Riesgo General
              </thead>
              <tbody>
                <tr>
                  Sin datos
                </tr>
              </tbody>
            </table>
          </v-card>
        </v-col>
      </v-row>
      <!--CUANDO ES NUEVA CONSULTA-->
      <v-row no-gutters justify="center" class="mt-3">
        <v-col cols="12" lg="3" md="3" sm="3" xs="3">
          <router-link>
            <button class="botonAmarillo" to="/dashboard">
              Regresar
            </button></router-link
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      titulo: "",
      expiration: false,
      respuesta: "",
      reporte: 0,
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      areas: [],
      area: 0,
      sucursales: [],
      sucursal: 0,
      alert: false,
      errors: [],
      show: false,
      porDisfrutar: false,
      enAnio: false,
      enMes: false,
      solicitados: false,
      vencidos: false,
      siguienteMes: false,
      comienzo: false,
      sinDatos: false,
      reporteGenerado: [
        {
          idEmpleado: 1,
          nombre: "X Z L",
          puesto: "Gerente",
          area: "RH",
          fechaIngreso: "20-12-20",
          fechas: [
            { fecha: "15-01-19" },
            { fecha: "16-01-20" },
            { fecha: "17-01-20" },
          ],
          periodo: "verano",
          vigencia: "Ok",
        },
        {
          idEmpleado: 2,
          nombre: "X Z L",
          puesto: "Supervisor",
          area: "RH",
          fechaIngreso: "20-12-20",
          fechas: [
            { fecha: "19-01-19" },
            { fecha: "11-01-20" },
            { fecha: "10-01-20" },
          ],
          periodo: "verano",
          vigencia: "Ok",
        },
      ],
      error: false,
    };
  },
  methods: {
    cambio() {
      this.porDisfrutar = false;
      this.enAnio = false;
      this.enMes = false;
      this.solicitados = false;
    },
    validacion() {
      this.errors = [];
      if (this.enterprise == 0) {
        this.titulo = "Error al generar el reporte";
        this.respuesta = "Es necesario corregir el siguiente error: ";
        this.errors.push("Se debe de elegir la razón social.");
        this.alert = true;
      }
      if (this.enterprise != 0 && this.reporte == 0) {
        this.titulo = "Error al generar el reporte";
        this.respuesta = "Es necesario corregir el siguiente error: ";
        this.errors.push("Se debe elegir el tipo de reporte");
        this.alert = true;
      }
      if (this.reporte == 1) {
        //días por disfrutar
        this.enAnio = false;
        this.enMes = false;
        this.solicitados = false;
        this.porDisfrutar = true;
        this.comienzo = true;
      }
      if (this.reporte == 2) {
        //disfrutados en el mes
        this.enAnio = false;
        this.solicitados = false;
        this.porDisfrutar = false;
        this.enMes = true;
        this.comienzo = true;
      }
      if (this.reporte == 3) {
        //disfrutados en el año
        this.enMes = false;
        this.solicitados = false;
        this.porDisfrutar = false;
        this.enAnio = true;
        this.comienzo = true;
      }
      if (this.reporte == 4) {
        //solicitados
        this.enMes = false;
        this.porDisfrutar = false;
        this.enAnio = false;
        this.solicitados = true;
        this.comienzo = true;
      }
    },
    listar() {
      this.show = true;
      var queryString =
        "?empresaId=" + this.enterprise + "&areaId=0" + "&sucursalId=0";
      axios
        .get(Server + "/vacaciones/reporte/3" + queryString, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.reporteGenerado = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerReportes() {
      this.reporte = 0;
      this.area = 0;
      this.sucursal = 0;
      this.reporte = 0;
      this.sinDatos = false;
      this.comienzo = false;
      this.areas = [];
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
                nombreCorto: value.nombreAreaCorto,
                empresa: value.empresaId,
                nombreEmpresa: value.empresa,
                nivelJerarquico: value.nivelJerarquico,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.sucursales = [];
      this.show = true;
      axios
        .get(Server + "/sucursales/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.sucursales = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      var x = (document.getElementById("reporte").disabled = false);
      var y = (document.getElementById("area").disabled = false);
      var z = (document.getElementById("sucursal").disabled = false);
    },
    /*Devuelve solo los dominios que pertenezcan a la categoria*/
    filtrar(dominio, categoria) {
      if (dominio.categoriaId == categoria.id) {
        return dominio.nombreDominio;
      }
    },
    /*Devuelve solo las dimensiones que pertenezcan a la categoria*/
    filtrarDimensiones(dimension, categoria) {
      if (categoria.id == dimension.dominio.categoria.id) {
        return dimension.nombreDimension;
      }
    },
    cambioArea() {
      this.sucursal = 0;
      this.reporte = 0;
      this.solicitados = false;
      this.enAnio = false;
      this.enMes = false;
      this.porDisfrutar = false;
    },
    cambioSucursal() {
      this.reporte = 0;
      this.solicitados = false;
      this.enAnio = false;
      this.enMes = false;
      this.porDisfrutar = false;
    },
    limpiar() {
      this.area = 0;
      this.sucursal = 0;
      this.reporte = 0;
      this.areas = [];
      this.sucursales = [];
      this.solicitados = false;
      this.porDisfrutar = false;
      this.enAnio = false;
      this.enMes = false;
      //this.reporteGenerado=[];
      var x = (document.getElementById("reporte").disabled = false);
      var y = (document.getElementById("area").disabled = false);
      var z = (document.getElementById("sucursal").disabled = false);
      this.validacion();
    },
  },
  created() {
    this.listar();
  },
};
</script>